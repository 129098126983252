var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "config" },
    [
      _c(
        "Card",
        [
          _c(
            "Row",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "margin-bottom": "15px",
              },
            },
            [
              _c("Col", [
                _c("span", { staticClass: "role-span" }, [
                  _vm._v("字段配置：" + _vm._s(_vm.tableName)),
                ]),
              ]),
              _c(
                "Col",
                [
                  _c(
                    "Button",
                    {
                      staticStyle: {
                        float: "right",
                        padding: "6px 12px",
                        "margin-right": "0 !important",
                      },
                      attrs: { loading: _vm.genLoading, type: "success" },
                      on: { click: _vm.toGen },
                    },
                    [_vm._v("保存&生成 ")]
                  ),
                  _c(
                    "Button",
                    {
                      staticStyle: { float: "right", padding: "6px 12px" },
                      attrs: { loading: _vm.columnLoading, type: "primary" },
                      on: { click: _vm.saveColumnConfig },
                    },
                    [_vm._v("保存 ")]
                  ),
                  _c(
                    "Tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "数据库中表字段变动时使用该功能",
                        transfer: true,
                        placement: "top-start",
                      },
                    },
                    [
                      _c(
                        "Button",
                        {
                          staticStyle: { float: "right", padding: "6px 12px" },
                          attrs: { loading: _vm.syncLoading, type: "info" },
                          on: { click: _vm.sync },
                        },
                        [_vm._v("同步 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("Table", {
            ref: "table",
            attrs: {
              loading: _vm.loading,
              border: "",
              columns: _vm.columns,
              data: _vm.data,
              sortable: "custom",
            },
          }),
        ],
        1
      ),
      _c(
        "Card",
        { staticStyle: { width: "650px", "margin-top": "10px" } },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c("span", { staticClass: "role-span" }, [_vm._v("生成配置")]),
              _c(
                "Button",
                {
                  staticStyle: {
                    float: "right",
                    padding: "6px 12px",
                    "margin-left": "0!important",
                  },
                  attrs: { loading: _vm.configLoading, type: "primary" },
                  on: { click: _vm.doSubmit },
                },
                [_vm._v("保存 ")]
              ),
            ],
            1
          ),
          _c(
            "Form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                size: "small",
                "labe-width": 78,
              },
            },
            [
              _c(
                "FormItem",
                { attrs: { label: "作者名称", prop: "author" } },
                [
                  _c("Input", {
                    staticStyle: { width: "40%" },
                    model: {
                      value: _vm.form.author,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "author", $$v)
                      },
                      expression: "form.author",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#C0C0C0", "margin-left": "10px" },
                    },
                    [_vm._v("类上面的作者名称")]
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: "模块名称", prop: "moduleName" } },
                [
                  _c("Input", {
                    staticStyle: { width: "40%" },
                    model: {
                      value: _vm.form.moduleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "moduleName", $$v)
                      },
                      expression: "form.moduleName",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#C0C0C0", "margin-left": "10px" },
                    },
                    [_vm._v("模块的名称，请选择项目中已存在的模块")]
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: "至于包下", prop: "pack" } },
                [
                  _c("Input", {
                    staticStyle: { width: "40%" },
                    model: {
                      value: _vm.form.pack,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pack", $$v)
                      },
                      expression: "form.pack",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#C0C0C0", "margin-left": "10px" },
                    },
                    [_vm._v("项目包的名称，生成的代码放到哪个包里面")]
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: "接口名称", prop: "apiAlias" } },
                [
                  _c("Input", {
                    staticStyle: { width: "40%" },
                    model: {
                      value: _vm.form.apiAlias,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "apiAlias", $$v)
                      },
                      expression: "form.apiAlias",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#C0C0C0", "margin-left": "10px" },
                    },
                    [_vm._v("接口的名称，用于控制器与接口文档中")]
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: "前端路径", prop: "path" } },
                [
                  _c("Input", {
                    staticStyle: { width: "40%" },
                    model: {
                      value: _vm.form.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "path", $$v)
                      },
                      expression: "form.path",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#C0C0C0", "margin-left": "10px" },
                    },
                    [_vm._v("输入views文件夹下的目录，不存在即创建")]
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  staticStyle: { "margin-left": "8px" },
                  attrs: { label: "去表前缀", prop: "prefix" },
                },
                [
                  _c("Input", {
                    staticStyle: { width: "40%" },
                    attrs: { placeholder: "默认不去除表前缀" },
                    model: {
                      value: _vm.form.prefix,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "prefix", $$v)
                      },
                      expression: "form.prefix",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#C0C0C0", "margin-left": "10px" },
                    },
                    [_vm._v("默认不去除表前缀，可自定义")]
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: "是否覆盖", prop: "cover" } },
                [
                  _c(
                    "RadioGroup",
                    {
                      staticStyle: { width: "40%" },
                      model: {
                        value: _vm.form.cover,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cover", $$v)
                        },
                        expression: "form.cover",
                      },
                    },
                    [
                      _c("Radio", { attrs: { label: "true" } }, [_vm._v("是")]),
                      _c("Radio", { attrs: { label: "false" } }, [
                        _vm._v("否"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#C0C0C0", "margin-left": "10px" },
                    },
                    [_vm._v("谨防误操作，请慎重选择")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }